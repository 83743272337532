<script>
import {mapActions, mapGetters} from "vuex";
import InputPassword from "@/components/Inputs/base/InputPassword.vue";
import InputLogin from "@/components/Inputs/base/InputLogin.vue";

export default {
  name: "AdminAuth",
  components: {InputLogin, InputPassword},
  data: function() {
    return {
      isValid: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("User", { getCredentialsCode: "getCredentialsCode"})
  },
  methods: {
    ...mapActions("User", {
      code: "code",
      login: "login",
      init: "init",
    }),
    async signin() {
      await this.login()
      await this.init()
      await this.$router.push("/admin")
    },
  }
}
</script>

<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-col
        cols="12"
        sm="10"
        md="8"
        lg="6"
        xl="4"
      >
        <p>Авторизация для администратора</p>
        <v-form
          v-model="isValid"
          @submit.prevent
        >
          <InputLogin :disabled="loading" />
          <InputPassword :disabled="loading" />
        </v-form>
        <v-btn
          :loading="loading"
          large
          block
          tile
          depressed
          @click="signin"
        >
          Войти
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>